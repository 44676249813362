<template>
  <div>
    <flixLoader />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
    }
  },
  methods: {
    getUser (callback) {
      this.$flix_post({
        url: 'user/get_user',
        data: {
          user: atob(this.$route.params.user)
        },
        callback: function (ret) {
          if (ret.data[0]) {
            ret.data[1].md5_id = atob(this.$route.params.user)
            window.localStorage.setItem('flix_login', btoa(JSON.stringify(ret.data[1])))
            this.$store.commit('setShareEmail', ret.data[1].email)
            this.$store.commit('setUser', ret.data[1])
            callback()
          }
        }.bind(this)
      })
    },
    setConfirmation (callback) {
      this.$flix_post({
        url: 'user/confirmation',
        data: {
          user: atob(this.$route.params.user)
        },
        callback: function (ret) {
          if (ret.data[0]) {
            callback()
            return false
          }
          this.$router.push({ name: 'dashboard' })
        }.bind(this)
      })
    }
  },
  mounted () {
    this.setConfirmation(function () {
      this.getUser(function () {
        this.$router.push({ name: 'dashboard' })
      }.bind(this))
    }.bind(this))
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
